import React from 'react'
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link } from "gatsby";
import Banner from '../../components/ads/banner'

function HowToCalculateTax() {
    const currentYear = new Date().getFullYear();
    return (
        <Layout>
            <div className='row mb-4'>
                <div className='col-12'>
                    <h1>How to File an Income Tax Return in Pakistan?</h1>
                    <p>Filing an income tax return can seem like a daunting task, but it is an important part of being a responsible citizen and ensuring that you are paying your fair share of taxes. In this article, we will walk you through the process of how to file your income tax return in Pakistan via online <a href='https://iris.fbr.gov.pk/' target='_blank'>FBR IRIS Portal</a>.</p>
                    <h2>Tax Return Eligibility</h2>
                    <p>First, let's make sure you are eligible to file an income tax return. In Pakistan, individuals who earn a salary or wages, or who own a business or receive rental income, are required to file an income tax return. If you fall into one of these categories and your gross income is in the these <Link to='/tax-slabs-2023-2024/'>tax slabs</Link>, then you will need to file an income tax return.</p>
                    <p>Fortunately, the government recently extended the deadline for filing an income tax return, so you still have some time to get your documents in order and complete the process.</p>
                    <h2>Tax Return Filing Preparation</h2>
                    <p>Before we get started, it is important to gather all of the necessary documents and information that you will need to complete your income tax return. This includes:</p>

                    <ul>
                        <li>Your CNIC number and password. If you don't know your password, you can retrieve it from the email address that you provided during registration.</li>
                        <li>A statement of your gross salary or wages for the tax year, which you can obtain from your employer.</li>
                        <li>Information on any tax deductions that have been made from your salary or wages throughout the year.</li>
                        <li>Any documents or information related to any other sources of income, such as business income or rental income.</li>
                    </ul>
                    <div className='mt-2 mb-2 text-center'>
                        <Banner></Banner>
                    </div>
                    <h2>Tax Return Filing Process in Pakistan</h2>
                    <p>Once you have gathered all of the necessary documents and information, you are ready to begin the filing process. Here's what you need to do:</p>
                    <ol>
                        <li>Go to the website for the Federal Board of Revenue (FBR) at <a href="https://fbr.gov.pk">fbr.gov.pk</a>.</li>
                        <li>Scroll down and click on the "IRIS Login" button.</li>
                        <li>On the login page, enter your CNIC number and password.</li>
                        <li>Once you are logged in, click on the "Declaration" tab with the blinking star in the top right corner.</li>
                        <li>In the second column, under "Returns and Statements," click on "Salary Return for Individuals (Classic View)."</li>
                        <li>Select the tax year for which you are filing the income tax from the dropdown menu under "Select Period."</li>
                        <li>The return form will open. In the first row, under code 1000 (income from salary), enter your gross salary or wages for the year. Remember to use your gross salary, not your net salary.</li>
                        <li>Click the "Calculate" button.</li>
                        <li>Click on the "Tax Chargeable/Payments" tab on the left side of the page.</li>
                        <li>Click on the "Adjustable Tax" button.</li>
                        <li>A new form will open. In the second row, under "Salary of Employees" and "Section 149," enter the tax that has been deducted from your salary throughout the year in the "Collected/Deducted" column.</li>
                        <li>Click the "Calculate" button again.</li>
                        <li>Go to the "Computations" tab. If your tax deducted is equal to your tax chargeable, then your tax has already been paid and you are done. If there is a difference, it will be shown as either an "Admitted Tax Liability" if the tax deducted is less than your tax chargeable, or as a "Refundable Income Tax" if your tax deductions are more than your chargeable tax.</li>
                    </ol>
                    <h2>What is Wealth Statement and How to File?</h2>
                    <p>In addition to filing your income tax return, you may also need to file a wealth statement under Section 116. This statement includes information about your personal expenses, assets, liabilities, and net assets. The wealth statement has four sections:</p>
                    <ul>
                        <li>Personal expenses</li>
                        <li>Personal assets</li>
                        <li>Liabilities</li>
                        <li>Reconciliation of net assets</li>
                        <li>Net assets</li>
                    </ul>
                    <p>To file your wealth statement, follow these steps:</p>
                    <ol>
                        <li>Go to the "Declaration" tab on the FBR website, as described above.</li>
                        <li>Under "Returns and Statements," click on "Wealth Statement."</li>
                        <li>Select the tax year for which you are filing the tax from the dropdown menu under "Select Period."</li>
                        <li>The wealth statement form will open. Follow the instructions on the form to enter your personal expenses, assets, liabilities, and net assets.</li>
                        <li>Click the "Calculate" button to see your net wealth for the tax year.</li>
                        <li>Review your statement carefully and make sure that all of the information is accurate. If everything is correct, submit your wealth statement.</li>
                    </ol>
                    <h2>Deadline for Filing Tax in Pakistan</h2>
                    <p>The deadline for filing your income tax return and wealth statement for the tax year {currentYear} is September 30, {currentYear}. Make sure to file your return and statement before this date to avoid any penalties or fines. If you need additional help or have questions about the filing process, you can contact the FBR or seek assistance from a tax professional.</p>
                    <h2>Tips for Filing Your Tax Return in Pakistan</h2>
                    <ul>
                        <li>Keep all of your tax-related documents organized and in a safe place. This includes pay stubs, receipts, and any other documentation that you may need to reference while filing your tax return.</li>
                        <li>If you are not sure how to complete a certain section of the tax return form, don't hesitate to ask for help. You can consult with a tax professional or seek guidance from the FBR website or customer service hotline.</li>
                        <li>Double-check all of the information that you enter on your tax return form to ensure that it is accurate. Even small mistakes can lead to delays or errors in the processing of your return.</li>
                        <li>Consider using tax software or an online tax filing service to help you complete your tax return. These tools can make the process faster and easier, and can help you to avoid common mistakes.</li>
                    </ul>
                    <p>By following these simple steps and keeping organized, you can easily file your income tax return and wealth statement for the tax year {currentYear}. By staying up to date with your tax obligations, you can ensure that you are in compliance with the law and avoid any potential issues with the FBR.</p>
                </div>
            </div>
        </Layout>
    );
}
export const Head = () => <Seo title="How to File an Income Tax Return in Pakistan | Tax Calculator Pakistan" description="Learn how to calculate your income tax in Pakistan with this step-by-step guide. Find out what tax brackets you fall into, how to file your tax returns, and what deductions and credits you may be eligible for." />

export default HowToCalculateTax;